module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WPBPB1DSFG"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quran Arabic English","short_name":"Quran","start_url":"/","background_color":"#1a405f","theme_color":"#1a405f","display":"standalone","icon":"static/images/quran-arabic-english-app-icon.png","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"310c6059bc89d2e0bdfcca45f1bee285"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
